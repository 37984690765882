.carousel-select {
  display: flex;
  position: relative;
  .carousel-select-item {
    display: none;
    height: 100%;
    width: 100%;
    &.-active {
      text-align: center;
      display: block;
    }
    &.passing-when-next {
      animation: passing-when-next-animation 0.5s ease-in;
    }
    &.coming-when-next {
      animation: coming-when-next-animation 0.5s ease-in;
    }
    &.passing-when-prev {
      animation: passing-when-prev-animation 0.5s ease-in;
    }
    &.coming-when-prev {
      animation: coming-when-prev-animation 0.5s ease-in;
    }
  }
  .carousel-select-control-next,
  .carousel-select-control-prev {
    cursor: pointer;
  }
  &.-light .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
  }
}

@keyframes passing-when-next-animation {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}
@keyframes coming-when-next-animation {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}
@keyframes passing-when-prev-animation {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
@keyframes coming-when-prev-animation {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
