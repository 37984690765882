.employees-details {
  .employee-photo-preview {
    height: 300px;
    max-height: 300px;
  }
}

.employee-photo {
  height: 50vh;
  max-height: 50vh;
}
