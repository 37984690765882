.collapsable {
  .toggle::before {
    content: "-";
    float: left;
    width: 15px;
  }
  &.collapsed .toggle::before {
    content: "+";
  }
}
