// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack
// END BOOTSTRAP

// Custom components
@import "~/frontend/scss/components/navbar";
@import "~/frontend/scss/blocks/carousel";
@import "~/frontend/scss/blocks/footer";
@import "~/frontend/scss/blocks/divider";
@import "~/frontend/scss/blocks/anchor_group";
@import "~/frontend/scss/blocks/system_anchor";
@import "~/frontend/scss/blocks/map";
@import "~/frontend/scss/blocks/complex_rich_text";
@import "~/frontend/scss/blocks/collapsable";
@import "~/frontend/scss/blocks/popover";
@import "~/frontend/scss/blocks/employees_details";
@import "~/frontend/scss/blocks/columns";
@import "~/frontend/scss/blocks/inventory_search";
@import "~/frontend/scss/blocks/form";
@import "~/frontend/scss/blocks/anchor";
@import "~/frontend/scss/blocks/visibility_toggle_area";
@import "~/frontend/scss/components/loader";

body {
  min-height: 100%;
}

.o-fit-cover {
  object-fit: cover;
}

.o-fit-contain {
  object-fit: contain;
}

.text-lg-nowrap {
  @include media-breakpoint-up(lg) {
    white-space: nowrap;
  }
}

.no-last-child-margin {
  :last-child {
    margin-bottom: 0;
  }
}

.h-fit-content {
  height: fit-content;
}

.mh-inherit {
  max-height: inherit !important;
}

.mw-inherit {
  max-width: inherit !important;
}

.mw-lg-100 {
  @include media-breakpoint-up(lg) {
    max-width: 100% !important;
  }
}

.mw-50 {
  max-width: 50% !important;
}

.mw-lg-50 {
  @include media-breakpoint-up(lg) {
    max-width: 50% !important;
  }
}

.mw-lg-25 {
  @include media-breakpoint-up(lg) {
    max-width: 25% !important;
  }
}

.shadow-md {
  box-shadow: 0 0 1rem -0.25rem $black;
}

.shadow-lg-none {
  @include media-breakpoint-up(lg) {
    max-width: 100% !important;
  }
}

.lh-md {
  line-height: 1.6 !important;
}

.widget-loader {
  margin: 40vh auto 50vh;
  display: flex;
  color: #13355a;
}

.widget-container {
  min-height: 600px;
  .widget-container-image {
    z-index: 1;
  }
  .widget-container-content {
    z-index: 2;
  }
}

.overflow-lg-initial {
  @include media-breakpoint-up(lg) {
    overflow: initial !important;
  }
}

.border-hover-danger {
  &:hover {
    border-color: $danger !important;
  }
}
.text-decoration-underline-hover:hover {
  text-decoration: underline !important;
}

.richtext-image {
  &.left {
    float: left;
    margin-right: 0.5rem;
  }
  &.right {
    float: right;
    margin-left: 0.5rem;
  }
}

.p-mb-0 p {
  margin-bottom: 0;
}

.home-page-form {
  .fields,
  .lock-image {
    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: auto;
      width: 900px;
    }
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include media-breakpoint-up(lg) {
      align-items: center;
      margin-right: 1.5rem;
    }
  }
}

.responsive-object {
  position: relative;
  width: 100%;
  height: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
