.map {
  height: 300px;
  @include media-breakpoint-up(md) {
    height: 450px;
  }
  @include media-breakpoint-up(lg) {
    height: 650px;
  }
  &.-small {
    @include media-breakpoint-up(md) {
      height: 400px;
    }
  }
}
