$googleColors: #ab47bc, #8d6e63, #bf360c, #ec407a, #78909c, #c2185b, #689f38,
  #004d40, #5d4037, #0288d1, #512da8, #7e57c2;

.carousel .carousel-indicators * {
  border: 1px solid $gray-800;
  width: 0.75rem;
  height: 0.75rem;
  &.active {
    background-color: $gray-800;
  }
}

.carousel-item.-hero {
  max-height: 30vw;
  height: 30vw;
}

.carousel-item-fake.-testimonials {
  margin-left: -100%;
}

.initials {
  min-width: 1.5rem;
  line-height: 1.5rem;
  box-sizing: content-box;
  @for $i from 1 through 12 {
    &[data-color-id="#{$i}"] {
      background-color: nth($googleColors, $i);
    }
  }
}

.carousel-item.-hero > .info {
  background-color: rgba($white, 0.85);
  width: 60%;
  @include media-breakpoint-up(lg) {
    width: 40%;
  }
  h2 {
    font-size: calc(2rem + 1vw);
  }
  h3 {
    font-size: calc(1rem + 1vw);
  }
  .btn {
    font-size: calc(0.75rem + 1vw);
  }
}
