.form {
  color: $dealersite-form-color;
  @if $dealersite-invert-form {
    @extend .bg-primary;
    button[type="submit"] {
      @extend .btn-secondary;
    }
  } @else {
    @extend .bg-secondary;
    button[type="submit"] {
      @extend .btn-primary;
    }
  }
}
.submit-loader {
  width: 2rem;
  height: 2rem;
}
