// TODO (Alexey): Move to anchor setting
.anchor-group.-minimal {
  a {
    box-shadow: inset 0 -0.5rem 1.25rem -1.25rem #000 !important;
  }
}
.anchor-group.-menu a {
  background-color: $gray-100;
  border: solid 1px $gray-200;
  &:hover {
    background-color: $gray-200;
  }
}
