.inventory-search {
  background-image: linear-gradient(to bottom, #fff 0%, #eee 100%) !important;
}
.inventory-search .search-content {
  height: 465px;
  .price-range {
    box-shadow: inset 0 -0.5rem 1.25rem -1.25rem #000 !important;
  }
  .body-style .link {
    height: calc(100% - 1.4rem);
  }
}
