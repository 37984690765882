@use "sass:math";

#navbar {
  z-index: 3;
}

.navbar.-top-header {
  @include media-breakpoint-up(lg) {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
}

@if ($disable-non-buttons-gradients) {
  .navbar,
  .dropdown-menu-dark .dropdown-item:hover {
    background-image: none;
  }
}

$ratio: 73;
$flag-width: 30px;

%lang {
  $width: $flag-width;
  $height: math.div($flag-width * 73, 128);
  min-width: $width;
  min-height: $height;
  width: $width;
  height: $height;

  display: inline-block;
  padding: 0;
  border: none;
  background-size: cover;
  background-position: center;
  border-radius: 0;
}

.lang-en {
  @extend %lang;
  background-image: url("~/frontend/media/us.png");
}

.lang-es {
  @extend %lang;
  background-image: url("~/frontend/media/mx.png");
}
