@font-face {
  font-family: "montserrat";
  src:
    url("~/frontend/fonts/Montserrat/montserrat-bold-webfont.woff2")
      format("woff2"),
    url("~/frontend/fonts/Montserrat/montserrat-bold-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "montserrat";
  src:
    url("~/frontend/fonts/Montserrat/montserrat-medium-webfont.woff2")
      format("woff2"),
    url("~/frontend/fonts/Montserrat/montserrat-medium-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "montserrat";
  src:
    url("~/frontend/fonts/Montserrat/montserrat-light-webfont.woff2")
      format("woff2"),
    url("~/frontend/fonts/Montserrat/montserrat-light-webfont.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "montserrat";
  src:
    url("~/frontend/fonts/Montserrat/montserrat-extralight-webfont.woff2")
      format("woff2"),
    url("~/frontend/fonts/Montserrat/montserrat-extralight-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

$font-family-sans-serif: "montserrat";
$input-border-radius: 0;
$display-font-weight: bold;
// Remove focus border for navbar toggler
$navbar-toggler-focus-width: 0;
$navbar-dark-color: #fff;
$navbar-light-color: rgba(#000, .65) !default;
// Disable gradients for all items except buttons (navbar, menu)
$disable-non-buttons-gradients: true !default;
$light: #fff;
