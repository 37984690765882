.complex-rich-text {
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
  &.lh-md {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.6 !important;
    }
  }
}
